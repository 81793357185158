export const routes = {
    agent: {
        overview: '/agent',
        products: (productId?: string) => `/agent/products/${productId || ''}`,
        bookings: (bookingId?: string) => ({
            home: '/agent/bookings',
            viewBooking: (itemId?: string) => `/agent/bookings/${bookingId}/view-booking/${itemId}`
        }),
        booking: (bookingId: string) => `/agent/booking/${bookingId}`,
        payment: (bookingId: string) => `/agent/pay/${bookingId}`,
        transactions: (groupId?: string) => ({
            home: '/agent/transactions',
            viewInvoice: (transactionId?: string) =>
                `/agent/transactions/${groupId}/view-invoice/${transactionId}`
        }),
        company: {
            setup: '/agent/company/setup',
            users: '/agent/company/users',
            notifications: '/agent/company/notifications',
            extensions: '/agent/company/extensions'
        },
        extensionCatalog: {
            home: '/agent/extensions/catalog',
            display: (agentId: string, catalogId: string, padding: string) =>
                `/agent/extensions/catalog/${agentId}/${catalogId}/${padding}`
        }
    },
    operator: {
        overview: '/operator',
        products: (productId?: string) => ({
            home: '/operator/products',
            new: `/operator/products/${SpecificRoutes.CREATE_NEW}`,
            link: (platform?: string) => `/operator/products/link/${platform}`,
            upsert: `/operator/products/${productId}`,
            sales: `/operator/products/${productId}/sales`
        }),
        company: {
            setup: '/operator/company/setup',
            users: '/operator/company/users',
            notifications: '/operator/company/notifications',
            extras: (extraId?: string) => ({
                home: '/operator/company/extras',
                new: `/operator/company/extras/${SpecificRoutes.CREATE_NEW}`,
                upsert: `/operator/company/extras/${extraId}`
            }),
            pickups: (pickupId?: string) => ({
                home: '/operator/company/pickups',
                new: `/operator/company/pickups/${SpecificRoutes.CREATE_NEW}`,
                upsert: `/operator/company/pickups/${pickupId}`
            })
        },
        bookings: (groupId?: string) => ({
            home: '/operator/bookings',
            moreDetails: (selectedId?: string) =>
                `/operator/bookings/${groupId}${
                    selectedId ? `?${PATH_IDS.SELECTED_ID}=${selectedId}` : ''
                }`,
            viewBooking: (bookingId?: string) =>
                `/operator/bookings/${groupId}/view-booking/${bookingId}`
        }),
        transactions: (groupId?: string) => ({
            home: '/operator/transactions',
            viewInvoice: (transactionId?: string) =>
                `/operator/transactions/${groupId}/view-invoice/${transactionId}`
        })
    },
    customer: {
        home: '/customer',
        login: '/customer/login',
        bookings: (groupId?: string) => ({
            home: '/customer/bookings',
            viewBooking: (bookingId?: string) =>
                `/customer/bookings/${groupId}/view-booking/${bookingId}`
        }),
        payment: (bookingId: string) => `/customer/pay/${bookingId}`
    },
    home: '/',
    login: '/login',
    signup: '/signup',
    fareharborLink: '/fareharbor-link',
    support: {
        home: '/support',
        create: '/support/create',
        viewCategory: (category: string) => `/support/${category}`,
        viewArticle: (category: string, articleId: string) => `/support/${category}/${articleId}`
    },
    terms: '/terms',
    forgotPassword: '/forgot-password',
    passwordless: '/passwordless',
    noPermission: '/no-permission',
    emailVerification: '/email-verification',
    stripeConnectedAccount: '/stripe-connected-account',
    newCompany: '/new-company',
    openSripe: '/open-stripe',
    notFound: '/not-found',
    successfulPage: {
        home: '/successful-page',
        dynamic: (mainTitle?: string, description?: string, fullName?: string, email?: string) =>
            `/successful-page?mainTitle=${mainTitle}&description=${description}&email=${email}&fullName=${fullName}`
    },
    bookingPdf: (groupId: string, bookingId: string) => `/booking-pdf/${groupId}/${bookingId}`,
    extensions: {
        button: {
            home: `/extensions/button/`,
            authentication: (agentId: string, productId: string) =>
                `/extensions/button/authentication/${agentId}/${productId}`,
            booking: (agentId: string, productId: string) =>
                `/agent/extensions/button/booking/${agentId}/${productId}`
        }
    }
};

export enum SpecificRoutes {
    CREATE_NEW = 'new',
    NOT_SELECTED = 'not_selected'
}

export enum PATH_IDS {
    AGENT_ID = 'agentId',
    GROUP_ID = 'groupId',
    BOOKING_ID = 'bookingId',
    PRODUCT_ID = 'productId',
    AMOUNT_REVERSED = 'amountReversed',
    TRANSACTION_ID = 'transactionId',
    NEW_FEEDBACK = 'feedback',
    EXTRA_ID = 'extraId',
    PICKUPS_ID = 'pickupId',
    SELECTED_ID = 'selectedId',
    ARTICLE_ID = 'articleId',
    SUPPORT_CAT = 'category',
    CATALOG_ID = 'catalogId'
}

export enum ROUTE_STATE {
    HEADER_BG_COLOR = 'headerBgColor',
    HEADER_FONT_COLOR = 'headerFontColor',
    BUTTON_RADIUS = 'buttonRadius',
    IS_PRODUCT_CATALOG = 'isProductCatalog',
    CATALOG_PADDING = 'padding'
}
