import { Box, Button, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useContext, useEffect, useRef, useState } from 'react';
import noProductImg from '../../../../../assets/images/no_image.png';
import { useQuery } from '../../../../../libs/utils/hooks';
import ImgWithFallback from '../../../common/ImgWithFallback';
import { ProductCatalogController } from './ProductCatalogController';

interface ProductCatalogProps {
    readonly textColor?: string;
    readonly btnColor?: string;
    readonly borderRadius?: number;
}

export default function ProductCatalog(props: ProductCatalogProps) {
    const imageElement = useRef<HTMLHeadingElement>(null);
    const [imageHeight, setImageHeight] = useState(0);
    const theme = useTheme();
    const query = useQuery();
    const textColor = '#' + (props.textColor || query.get('textColor'));
    const borderRadius = props.borderRadius || query.get('borderRadius');
    const btnColor = '#' + (props.btnColor || query.get('btnColor'));
    const controller = useContext(ProductCatalogController);

    const calcImageHeight = () => {
        const width = imageElement.current ? imageElement.current.offsetWidth : 0;
        const height = width * 0.75;
        setImageHeight(height);
    };

    useEffect(() => {
        calcImageHeight();
        window.addEventListener('resize', calcImageHeight);
        return () => {
            window.removeEventListener('resize', calcImageHeight);
        };
    }, []);

    return (
        <Grid
            item
            container
            justifyContent="center"
            style={{
                zIndex: 1,
                backgroundColor: controller.isParentDivChecked
                    ? theme.palette.background.buttonBg
                    : theme.palette.common.white,
                border: controller.isParentDivChecked
                    ? '1px solid' + theme.palette.secondary.main
                    : 'none',
                padding: controller.parentDivPadding,
                margin: '20px 0',
                minWidth: 'fit-content',
                width: 'auto'
            }}>
            <Box
                style={{
                    boxShadow: '0px 16px 35px 0px' + theme.palette.text.customerDescription + 26,
                    borderRadius: 6,
                    zIndex: 2
                }}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: '360px',
                            backgroundColor: 'transparent'
                        }}>
                        <Grid item xs={12} ref={imageElement}>
                            <ImgWithFallback
                                alt={'No Image'}
                                width={'100%'}
                                height={'100%'}
                                src={
                                    'https://firebasestorage.googleapis.com/v0/b/parseports-dev4.appspot.com/o/products%2F1VqE6B9Mug3c13uMeXDW%2F183_image_md.webp?alt=media&token=8c2c7a15-2b46-43da-ba72-3e0c3f460735'
                                }
                                fallbackSrc={noProductImg}
                                skeletonStyles={{
                                    borderRadius: '6px 6px 0 0',
                                    height: imageHeight
                                }}
                                style={{
                                    borderRadius: '6px 6px 0 0',
                                    cursor: 'pointer'
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                padding: '20px 18px 4px',
                                borderRadius: '0 0 6px 6px',
                                border: '1px solid' + theme.palette.text.companyText + 66,
                                borderTop: 'none',
                                background: theme.palette.background.default
                            }}>
                            <Typography variant="h6">Product Name</Typography>
                            <Grid
                                item
                                xs={12}
                                style={{ height: 120, overflow: 'auto', margin: '12px 0' }}>
                                <Typography
                                    variant="body2"
                                    style={{
                                        color: theme.palette.text.loginPages.textPolicy,
                                        lineHeight: 1.6
                                    }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                                    enim ad minim veniam.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                container
                                xs={12}
                                justifyContent="space-between"
                                alignItems="center"
                                style={{
                                    padding: '12px 0 18px'
                                }}>
                                <Grid
                                    item
                                    style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Typography variant={'h6'}>$450</Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="text"
                                        style={{
                                            minHeight: 48,
                                            color: textColor,
                                            background: btnColor,
                                            borderRadius: Number(borderRadius),
                                            boxShadow:
                                                '0px 16px 35px 0px' +
                                                theme.palette.text.customerDescription +
                                                '26'
                                        }}>
                                        <Typography variant="h6">Book Now</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
}
